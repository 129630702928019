<template>
  <div>
    <!-- have to set position: inherit; sue to default value make element overflow by some reason -->
    <MultiSelect
      v-model="model"
      class="full-dropdown rule-dropdown"
      option-disabled="allCampaignsSelected"
      :options="options"
      option-label="name"
      placeholder="Select one or more rules"
      style="width: 250px;"
      :show-toggle-all="false"
      @show="onOpen"
      @hide="onClose"
    >
      <template #header>
        <div class="header">
          <router-link
            :to="{name: 'bidRules', query}"
          >
            Create new rule
          </router-link>
        </div>
      </template>
    </MultiSelect>
  </div>
</template>

<script>
export default {
  inject: ['query'],
  props: {

    rules: {
      type: Object,
      default: undefined,
    },
    allRules: {
      type: Object,
      required: true,
    },
    onUpdate: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      model: this.rules,
      options: this.allRules,
      oldRules: null,
      newRules: null,
    };
  },
  watch: {
    model: {
      handler(newValue) {
        this.newRules = newValue;
      },
    },
  },
  methods: {
    clickListener(event) {
      this.$confirm.require({
        message: `Please disable the “Select all campaigns” option from '${event.currentTarget.children[1].textContent}' rule if you want to deselect it`,
        target: event.currentTarget,
        acceptLabel: 'Go to Rules page',
        header: 'Rule cannot be deselected',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$router.push({ path: '/bidrules' });
        },
      });
    },
    onOpen() {
      this.oldRules = this.rules;
      const elements = document.querySelectorAll('.p-multiselect-item.p-highlight.p-disabled');
      elements.forEach((el) => {
        el.classList.remove('p-disabled');
        el.classList.add('bf-disabled');
        el.addEventListener('click', this.clickListener);
      });
    },
    onClose() {
      const elements = document.querySelectorAll('.p-multiselect-item.p-highlight.p-disabled');
      elements.forEach((el) => {
        el.removeEventListener('click', this.clickListener);
      });
      if (this.newRules === null) return;
      this.onUpdate(this.oldRules?.length ? this.oldRules : null, this.newRules?.length ? this.newRules : null);
    },
  },
};
</script>

<style>
.header {
  margin: 1.25rem 1.25rem 0 1.25rem;
}
</style>
